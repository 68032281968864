<template>
  <section class="content">
    <b-overlay :show="showLoader" no-wrap></b-overlay>
    <div class="container-fluid">
      <div class="row">
        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
          <div class="card">
            <div class="card-body pt-2 bg-light">
              <div class="row">
                <div class="col-md-8 text-left px-0 bor-bot pb-3">
                  <h1 class="m-0 text-dark pt-2 text-left px-0">Partner Page</h1>
                </div>
                <div class="col-md-4 bor-bot pr-0">
                  <div class="text-right pb-3 pt-1">
                    <ol class="breadcrumb float-sm-right pr-0">
                      <li class="breadcrumb-item"><a href="/member/dashboard">Home</a></li>
                      <li class="breadcrumb-item"><Breadcrumbs/></li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 px-0 mt-3 faqsearchsec faqseclisttable">
                  <b-card class="shadow" :id="'partner_'+partner.id" v-for="(partner,index) in partners" :key="index">
                    <b-card-body><img :src="basePath+'images/partners/'+partner.logo" class="float-left mr-4 mb-4 img-fluid" style="max-width: 150px; min-height: 150px" >
                      <span v-html="partner.description">  </span>
                    </b-card-body>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <b-modal size="lg" id="modal-1" ref="my-modal" title="Answer" :modal-class="textareamodal">
      <p class="my-4" v-html="answer"></p>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
import $ from "jquery";

export default {
  name: "partner",
  data(){
    return {
      partners:null,
      showLoader:false
    }
  },
  methods:{
    getPartners(){
      this.showLoader = true;
      axios.get(this.basePath+'api/partners/getPartners',{ headers:this.memberHeaders })
          .then(function (response) {
            if (response.data.status == 'success') {
              this.partners = response.data.data;
            } else {
              console.log(response);
            }
          }.bind(this)).finally(()=>{
            if(this.$route.params.id!=undefined) {
                $('html, body').animate({
                  scrollTop: $("#partner_" + this.$route.params.id).offset().top
                }, 1000);
            }
            this.showLoader = false;
      })
          .catch(function (error) {
            console.log(error);
          });
    },
  },
  mounted() {
    this.getPartners();
  }
}
</script>

<style scoped>

</style>